.About {
  display: grid;
  margin: 7em 0.7em 0 0.7em;
  grid-template-columns: 30% auto;
  grid-template-rows: 25% auto auto;
}

.about-img {
  width: 100%;
  border-radius: 20%;
  margin-top: 1em;
}

.about-imgcontainer {
  display: flex;
  align-items: center;
  grid-row: 1 / span 2;
}

.about-span {
  margin-left: 2em;
}

.about-hello {
  margin-top: 1em;
  margin-left: 1em;
}

.about-desc {
  margin-left: 1em;
  grid-column: 2 / span 1;
  grid-row: 2 /span 1;
}

.about-p {
  margin-top: 0;
  margin-bottom: 0;
}


.about-desc2 {
  grid-column: 1 / span 2;
}

.hide-until {
  display: none;
}

@media screen and (min-width: 480px) {
  .About {
    margin: 8em 0.7em 0 0.7em;
  }
}

@media screen and (min-width: 590px) {
  .About {
    margin: 9em 0.7em 2em 0.7em;
  }
}

@media screen and (min-width: 680px) {
  .About {
    margin: 10em 0.7em 2em 0.7em;
  }
}

@media screen and (min-width: 750px) {
  .About {
    margin: 13em 0.7em 2em 0.7em;
  }

  .hide-until {
    display: block;
    margin-top: 1em;
  }

  .about-p {
    margin-top: 1em;
  }

  .about-desc2 {
    display: none;
  }
}

@media screen and (min-width: 1060px) {
  .About {
    margin: 16em 0.7em 2em 0.7em;
  }
}

@media screen and (min-width: 1380px) {
  .About {
    margin: 15em 0.7em 2em 0.7em;
  }

  .about-img {
    width: 70%;
  }

  .about-imgcontainer {
    display: flex;
    justify-content: center;
  }
}
