.Portfolio {
  padding: 1.5em;
  text-align: center;
  background-color: #0a0a0a;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.portfolio-img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1em;
}

.portfolio-text {
  color: #f5f5f5;
  margin-bottom: 0px;
  margin-top: 0px;
}

@media screen and (min-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr 1fr;
  }

  .portfolio-item {
    margin: 1em;
  }
}
