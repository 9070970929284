body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  font-family: 'Roboto', sans-serif;
}

h1, h2 {
  font-family: 'Roboto Slab', serif;

}

 h3 {
   font-family: 'Raleway', sans-serif;

 }
