.PortfolioDetail {
  display: flex;
  height: 100vh;
  flex-flow: column nowrap;
}

.detail-contact {
  margin-top: auto;
}

.detail-body {
  margin-top: 10em;
}

.portfolio-detail-text {
  color: black;
  text-align: center;
  font-style:
}

.detail-img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1em;
}

.image-container {
  padding-left: 1em;
  padding-right: 1em;
}

.detail-description {
  background-color:  #0a0a0a;
  min-height: 35vh;
  color: white;
}

.detail-text {
  display: flex;
  flex-flow: column;
  padding: 1.5em 0 1.5em 0;
  margin: 0 1em 0 1em;
  align-items: center;
}

.detail-link {
  margin-top: 0.5em;
  text-decoration: underline;
}

@media screen and (min-width: 900px) {
  .PortfolioDetail  {
    margin-top: 2em;
  }
}

@media screen and (min-width: 1320px) {
  .PortfolioDetail  {
    margin-top: 5em;
  }

  .image-container {
    display: flex;
    justify-content: center;
  }

  .detail-img {
    width: 80%;
  }

  .detail-text {
    display: flex;
    flex-flow: column;
    height: 35vh;
    margin: 0 12em 0 12em;
    align-items: center;
  }
}
