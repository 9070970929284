.App-header {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 6px 2px #d4d5d6;
  padding: 1em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fafafa;
}

a {
  text-decoration: none;
}

.name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.name-desc {
  margin-top: 0px;
}

.name-header {
  margin-bottom: 0px;
}

.App-logo {
  width: 60%;
}

.nav {
  display: flex;
}

.logo-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 780px) {
  .nav {
    font-size: 0.8em;
  }
  .nav-text {
    margin: 0.2em;
  }
}

@media screen and (min-width: 1024px) {
  .name-container {
    font-size: 1.2em;
  }
  .nav-text {
    font-size: 1.3em;
    margin: 0.25em;
  }

  .App-logo {
    width: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .name-container {
    font-size: 1.8em;
  }
  .nav-text {
    font-size: 1.5em;
    margin: 0.4em;
  }
}
